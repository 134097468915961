/* Common Css Start */
.home-page-conatiner{
  background-color: #fff !important;
  font-family: Montserrat !important;
}

.main-container {
  position: relative;
  padding: 115px 15px 60px 15px !important;
  min-height: calc(100vh - 200px);
}

.landing-page-conatiner{
  min-height: calc(100vh - 200px);
  padding-top: 115px !important;
}

.lp-main-container{
  position: relative;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.font-size-9 {
 font-size: 9px;
}
.font-size-10 {
 font-size: 10px;
}
.font-size-11 {
 font-size: 11px;
}
.font-size-12 {
 font-size: 12px;
}
.font-size-13 {
 font-size: 13px;
}
.font-size-14 {
 font-size: 14px;
}
.font-size-15 {
 font-size: 15px;
}
.font-size-16 {
 font-size: 16px;
}
.font-size-18 {
 font-size: 18px;
}
.font-size-19 {
 font-size: 19px;
}
.font-size-20{
 font-size: 20px;
}
.font-size-22{
 font-size: 22px;
}
.font-size-25{
 font-size: 25px;
}

.font-color-grey {
  color: #999999;
}

.font-color-white {
  color: #ffffff !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: bold;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-18 {
  padding-top: 18px;
}

.pt-110 {
  padding-top: 110px!important;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.row-mt {
   padding-left: 50px;
   padding-right: 50px;
}

.text-center {
  text-align: center;
}

.pl-45 {
  padding-left: 45px;
}

.pl-85 {
  padding-left: 85px
}

.btn-pt-25 {
  padding-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-18 {
  margin-top: -18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mtm-3 {
  margin-top: -5px;
}

.ml-40 {
  margin-left: 40px;
}

.section-pb-50 {
  padding-bottom: 50px !important;
  padding-top: 0 !important
}

.section-pb-0 {
  padding: 0 !important;
}

button.btn.btn-primary.sm-white {
     /* width: 105px; */
     max-width: 200px;
     padding: 2px 20px;
     font-size: 14px;
     background-color: #fff;
     color: #1b3272;
     border: 1px solid #1b3272;
     border-radius: 3px;
     margin-right: 8px;
     margin-left: 17px;
}
 .sm-primary {
    /* width: 105px;
    min-width: 105px; */
    padding: 3px 20px;
    font-size: 14px;
    background-color: #1b3272;
    color: #fff;
    border: 1px solid #1b3272;
    border-radius: 3px !important;
}
.sm-primary-1{
  font-size: 17px!important;
}
.btn-lg-white {
  padding: 8px 20px;
  font-size: 11px;
  background-color: #fff;
  color: #1b3272 !important;
  border: 1px solid #1b3272;
  border-radius: 3px !important;
  font-weight: 600;
  letter-spacing: .5px;
}

.btn-lg-white:hover {
  background-color: #1b3272;
  color: #fff !important;
}
#yearly-Report h1{
font-size: 30px;
}
#yearly-Report .vi-info-p p{
  font-size: 13px!important;
}
#yearly-Report .ReportSubHeading{
  font-size: 22px;
}
#yearly-Report .read-more-btn{
  padding: 10px 15px;
    font-size: 14px;
}
h1 {
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #131415;
  padding-bottom: 15px;
}

.h3-text {
  font-weight: bold;
  letter-spacing: -0.38px;
  color: #131415;
}

.float-r {
  float: right !important;
}

.float-l {
  float: left;
}

.sm-card {
 width: 200px;
 height: 230px;
 border-radius: 5px;
 margin-left: 15px;
 box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
 background-blend-mode: overlay, normal;
 background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.lg-card {
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  width: 250px;
  min-height: 375px;
  border-radius: 10px;
  background-blend-mode: overlay, normal;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.border, .loader-demo-box {
  border: none !important;
}
/* Common Css End */

/* Footer Css Start */
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #2b3e9d;
  color: white;
  text-align: center;
  height: 200px;
}

.footer ul li a {
  text-align: left;
  display: block;
  line-height: 1.5 !important;
}

.footer ul li {
  margin-bottom: 5px;
}

.footer .col-wd-1 {
  flex: 0 0 38% !important;
  max-width: 38% !important;
}

.footer .col-wd-2 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.footer .col-wd-3 {
  flex: 0 0 22% !important;
  max-width: 22% !important;
}

.footer .social-icon-list {
  float: left;
  margin-left: 23px;
}

.footer .social-icon {
  margin-right: 85px;
  margin-left: 30px;
}

.footer .social-icon li {
  display: inline-block;
}

.footer .social-icon li a {
  line-height: 1.5 !important;
  margin-left: 15px;
  font-size: 20px;
}

.footer img {
    max-width: 85% !important;
    height: auto;
    width: 300px;
}

/* Footer Css End */


/* Header Css Start */

.main-hb img {
    height: auto;
    border-radius: 50%;
    object-fit: contain;
    width: 35px;
    height: 30px;
}

.formpage header a.logo img {
  width: 19rem!important;
}
@media (max-width:992px){
  .formpage header a.logo img {
    width: 13rem!important;
    
  }
  .login-desi{
    margin-top: 10px;
  }
  .sm-white {
    margin-top: 10px;
  }
}
.navbar-top {
  box-shadow: 5px 1px 10px #c1bcbc;
}

 ul#accordion {
    padding-top: 20px !important;
}

.p-img img{
  width: 28px !important;
}

/* @media (min-width: 768px){
  ul#accordion {
    padding-top: 20px !important;
  }
} */

/* .login-btn-wh {
    padding: 5px 20px !important;
    background-color: #fff !important;
    border: 1px solid #1b3272;
    border-radius: 3px;
    font-size: 11px !important;
    font-weight: 600;
    color: #1b3272 !important;
    margin-top: -4px;
    letter-spacing: .5px;
    line-height: inherit;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
    text-transform: uppercase;
    width: auto;
    display: inline-block;
} */

@media (min-width: 768px){
  .formpage nav.navbar.navbar-default ul.nav>li>a.login-btn-wh  {
      padding: 5px 20px !important;
  }
}

.header-main .dropdown:hover ul.dropdown-menu.animated {
    left: 20px !important;
}

.formpage .dropdown:hover ul.dropdown-menu.animated {
    top: 35px !important;
}

.btn-w-135 {
  width: 135px !important;
}


/* Header Css End */

/* Home Page Css Start */
section.section-1 {
  padding: 0 0 50px 0;
}

section.section-2 {
  padding: 50px 0;
}

section.section-3 {
  padding: 50px 0;
}

section.section-grey {
  padding: 50px 0;
  /* opacity: 0.3; */
  background-color: #edf2f3;
}

.title-heading {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding-bottom: 25px;
}

.home-in {
  text-decoration: none !important;
}

.in-cursor {
  cursor: pointer;
}

.Early-Access-Release,
.Made-For-a-Purpose {
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #131415;
  padding-bottom: 15px;
}

.section-1-title {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  padding-bottom: 15px;
}

.img-div img {
  width: 100%;
  height: 500px;
}

.Sign-up-for-Free  {
  padding:12px 15px;
  font-weight: bold;
  letter-spacing: 1.7px;
  text-align: center;
  color: #ffffff;
}

.section-2 .ticker-sm img {
  border-radius: 25%;
  object-fit: contain;
  width: 24px;
  height: 24px;
}

.section-2 .ticker-num{
  font-size: 26px;
  font-weight: bold;
  color: #2b292d;
  vertical-align: middle;
  padding-left: 10px;
}

.section-2 .ticker-title {
  font-weight: 600;
  color: #2b292d;
  padding-top: 5px;
}

.purpose-sm .purpose-heading{
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
}

.purpose-sm  img {
  border-radius: 25%;
  object-fit: contain;
  width: 40px;
  height: 40px;
}

.purpose-sm .purpose-grey-text{
  opacity: 0.7;
  line-height: 1.2;
  text-align: center;
  color: #000000;
  padding-top: 10px;
  margin: 0 35px;
}

.purpose .Made-For-a-Purpose {
  text-align: center;
}

.purpose p {
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #2b292d;
  margin: 0px 50px;
}

.blue-section {
  background-color: #2b3e9d;
  padding: 40px 0 !important;
  position: relative;
}


img.img-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

img.img-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.brand-tag {
  font-weight: bold;
  line-height: 1.21;
  text-align: center;
  color: #131415;
}

.service-tag {
  font-weight: bold;
  line-height: normal;
  color: #999999;
  padding-bottom: 15px;
}

.mtr-pl {
  font-weight: 500;
  line-height: 1.33;
  color: #2b292d;
  margin: 0px 50px 0 0;
  text-align: justify;
}

.mtr-sm {
  margin-left: -12px;
}

.mtr-sm img {
  border-radius: 25%;
  object-fit: contain;
  width: 30px;
  height: 40px;
}

.mtr-text {
  margin-top: -31px;
  margin-left: 40px;
  width: 60%;
}

.mtr-heading {
  font-weight: 600;
  padding-bottom: 10px;
}

.mtr-grey-text {
  opacity: 0.7;
  line-height: 1.2;
  color: #000000;
}

p.feature {
  font-weight: bold;
  line-height: 1.55;
  color: #f1592b;
  padding-bottom: 10px;
}

p.feature-pl {
  font-weight: 600;
  line-height: 1.65;
  letter-spacing: normal;
  color: #666666;
}

.feature-img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  /* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1); */
  background-blend-mode: overlay, normal;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.home-insight-card {
  width: 235px !important;
  min-height: 361px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 10px;
  background-blend-mode: overlay, normal;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.home-insight-card .card-body {
  padding: 15px 15px !important;
}

.home-insight-card .card-pera {
   font-weight: 600;
   line-height: 1.25;
   text-align: center;
   color: #a0acba;
}

.home-insight-card .card-text {
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: #2b292d;
  padding-top: 10px;
}

.home-insight-card .card-img-top {
  border-radius: 10px;
}

.get-start-btn {
  padding: 8px 20px !important;
  font-size: 11px;
  background-color: #1b3272;
  color: #fff;
  border: 1px solid #1b3272;
  border-radius: 3px !important;
  font-weight: 600;
  letter-spacing: .5px;
}

.new-letter-img {
  width: 100%;
  height: auto;
  margin-top: -60px;
}

.started-btn {
  padding: 8px 20px;
  font-size: 12px;
  background-color: #3040c4;
  color: #fff;
  border: 1px solid #3040c4;
  border-radius: 3px !important;
}

.aml-api-pera {
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #2b292d;
  margin: 0px 50px;
}

#mc_form input {
  width: 68% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 2px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-appearance: none;
  outline: none;
  max-width: 68%;
  margin-right: 0.25rem !important;
  margin-top: 10px;
}

.subscribe-hmc button {
  padding: 10px 20px !important;
  font-size: 12px !important;
  background-color: #3040c4 !important;
  color: #fff;
  border: 1px solid #3040c4 !important;
  border-radius: 3px !important;
}

.img-div .slick-list{
  height: 400px !important;
}

.early-h {
  font-weight: bold !important;
  padding-top: 10px;
  margin-bottom: 30px;
  color: #2a3e9d;
}

/* Home Page Css End */

/* API Page Css Start */

.sm-card .card-body {
  padding: 25px 20px;
}

.sm-card .card-body img {
  width: 90%;
  margin-bottom: 25px;
}

.sm-card .card-text {
  font-weight: 600;
  line-height: 1.27;
}
/* API Page Css End */

/* Subscribe Page Css Start */
.mailchimp-form form {
    display: block;
    position: relative;
    text-align: left;
}

.mailchimp-form {
    background: #fff;
    clear: left;
    font: 14px 'Montserrat', sans-serif;
}

.mailchimp-form input {
    border: 1px solid  #090763 !important;
    border-radius: 3px;
    margin-bottom: 40px;
    width: 65%;
    padding: 8px 15px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    background-color: #ffffff;
    font-size: 12px;
}

.mailchimp-form button {
    display: block;
    padding: 10px 20px;
    font-size: 11px;
    background-color: #1b3272;
    color: #fff;
    border: 1px solid #1b3272;
    border-radius: 3px !important;
    width: 65%;
    font-weight: 600;
    letter-spacing: .5px;
}
/* Subscribe Page Css End */

/* Insight Page Css Start */
.nav-list {
    padding-top: 45px;
    padding-bottom: 20px;
}
.nav-list ul {
    list-style: none;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
}

.nav-list ul li {
  display: inline-block;
  padding: 0.30rem 1rem;
  cursor: pointer;
  color: #353333;
  font-size: 12px;
}

.nav-list li.active {
  border-bottom: 3px solid #070763;
  font-weight: bold;
  letter-spacing: 1.9px;
  text-align: center;
  color: #070763;
  font-size: 12px;
}
.nav-list li a {
  color: inherit!important;
}
.lg-card .card-text {
  font-weight: 600;
  line-height: 1.27;
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: #2b292d;
}

.lg-card .card-pera {
   font-weight: 600;
   line-height: 1.25;
   text-align: center;
   color: #a0acba;
}.lg-card .card-pera1 {
  font-weight: 600;
  line-height: 1.25;
  color: #a0acba;
  text-align: justify!important;
  word-spacing: -2px;
}
.card-pera1 p{
  font-size: 12.5px !important;
  line-height: normal!important;
  margin-bottom:10px ;
}
.card-pera1 strong{
  font-weight: bolder;
}
.lg-card1{
  width: 330px;
}
.lg-card .card-body {
    /* padding: 20px 25px !important; */
    padding: 20px 12px !important;
}

.lg-card .card-img-top {
  border-radius: 10px;
}

/* .lg-card:hover {
	-webkit-transform: translate(0, -8px);
	-moz-transform: translate(0, -8px);
	-ms-transform: translate(0, -8px);
	-o-transform: translate(0, -8px);
	transform: translate(0, -8px);
	box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);
} */

.lg-card:hover .card-img-top {
  opacity: 0.3;
}


.lr-btn-block {
  display: block;
}

.lr-btn {
  padding: 8px 20px;
  margin-top: 35px;
}

.vi-tab .vi-img {
  width: 275px;
  height: 190px;
  border-radius: 15px;
}

.vi-tab .card .card-body {
    padding: 1.25rem;
}

.vi-tab .text-left {
  padding-left: 0;
}

.vi-card-bold {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  color: #131415;
}

.vi-card-pera {
  font-weight: 500;
  line-height: 1.75;
  color: #666666;
}

.vi-rm-btn {
  font-weight: 600;
  color: #070763;
}

.vi-rm-btn:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #070763;
}

/* Insight Page Css End */

/* Contact Page Css Start */
.contact-btn {
  display: block;
  padding: 10px 13px;
  font-size: 11px;
  background-color: #1b3272;
  color: #fff;
  border: 1px solid #1b3272;
  border-radius: 3px !important;
  width: 100%;
  font-weight: 600;
  letter-spacing: .5px;
}

.contact-form label {
  line-height: 1.38;
  color: #2c3241 !important;
  font-size: 14px;
}

.contact-form input {
  font-size: 12px;
  color: #2c3241;
  /* opacity: 0.6; */
  border-radius: 2px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.contact-form select {
  border-radius: 3px;
  margin-bottom: 40px;
  padding: 8px 15px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  background-color: #ffffff;
  font-size: 12px;
  height: inherit !important;
  margin-bottom: 0;
}

.contact-img {
  /* width: 65%;
  height: auto;
  margin-left: 60px; */
  width: 90%;
  height: 390px;
  margin-left: 60px;
  text-align: center;
  margin-top: 15px;
}

.subscribe-img {
  width: 65%;
  height: auto;
  margin-left: 50px;
  margin-top: -10px;
}

.subscribe-img {

}
/* Contact Page Css End */

/* Visulation DEtial Page Css Start */

img.vi-info-img {
  width: 75%;
  height: auto;
}

.vi-info-p {
  font-weight: 500;
  line-height: 1.75;
  color: #131415;
}

.vi-info-p iframe {
  width: 99% !important;
  height: 545px;
}

.vi-info-p p,
.vi-info-p ul li,
.vi-info-p ol li {
  font-size: 12px;
  text-align: justify;
}

.vi-info-blue-block {
  font-size: 30px;
  height: 55px;
  line-height: 22px;
  padding: 15px 0;
  width: 55px;
  font-weight: 500;
  margin-top: 5px;
  float: left;
  margin: 0 20px 0 0;
  text-align: center;
  background-color: rgb(27, 50, 114);
  color: #fff;
}

/* Visulation DEtial Page Css End */

/* API doc Page Css Start */

.sidebar {
  margin: 0;
  padding: 0;
  background-color: #f1f1f1;
  position: sticky;
  height: 400px;
  overflow: auto;
  /* width: auto !important; */
  width: 230px !important;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
  font-weight: bold;
}

.activeMenu {
  background-color: #c1c1c1;
  font-weight: bold;
}

/* .sticky {
  position: sticky !important;
  top: 0;
}

.content-section {
   background: lavender;
   height: 1000px;
   min-height: 100vh;
   padding: 1em;
 }

 .sidebar-section {
   height: 100%;
 }

 .sidebar-content {
   background: cornflowerblue;
   padding: 1em;
 }

 .placeholder-example {
       background: lavender;
       height: 100vh;
       padding: 1em;
     }

     .sticky-example {
       background: cornflowerblue;
       padding: 1em;
     } */
/* API doc Page Css End */

/* Pricing Page Css Start */
.plan-card {
  min-height: 350px !important;
}

.plan-card:hover {
	-webkit-transform: translate(0, -8px);
	-moz-transform: translate(0, -8px);
	-ms-transform: translate(0, -8px);
	-o-transform: translate(0, -8px);
	transform: translate(0, -8px);
	box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);
}

.plan-price-tag {
  padding-top: 30px;
}

span.plan-ht {
  font-size: 32.4px;
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  color: #2b292d;
  /* margin-right: 10px; */
}

span.plan-free{
  font-size: 28px;
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  color: #2b292d;
}

span.plan-gery-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  color: rgba(43, 41, 45, 0.4);
}

.plan-card-body .card-pera {
  color: #2b292d !important;
}

.plan-card-body button {
  display: block;
  padding: 6px 20px !important;
  font-size: 11px;
  background-color: #1b3272;
  color: #fff;
  border: 1px solid #1b3272;
  border-radius: 3px !important;
  width: 100%;
  font-weight: 600;
  letter-spacing: .5px;
}

.plan-body-li ul {
  list-style: none;
  text-align: left;
  margin-left: -38px;
  min-height: 86px;
}

.plan-body-li ul li {
  font-size: 11px;
  font-weight: 500;
  line-height: 2;
  color: #2b292d;
}

.plan-body-li ul li:before {
  content: '✓';
  padding-right: 5px;
}

/* .section-pb-50 tr {
  border-radius: 12px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(216, 216, 216, 0.65);
  background-color: #fff;
  margin-top: 8px;
  display: block;
  vertical-align: middle;
  font-size: 12px;
}
.section-pb-50 thead tr {
  background-color: #d8d8d8;
}
.section-pb-50 .table th {
    border: solid 1px rgba(216, 216, 216, 0.65) !important;
    font-size: 12px;
} */

.section-pb-50 .table {
  font-size: 12px;
}

.accordion-tag .card {
  background-color: #f3f6ff;
  padding: 10px 5px;
  margin-bottom: 10px;
  border: 1px solid #ece7e7;
  /* background-color: white; */
  box-shadow: 1px 3px 5px #d4d1d1;
  border-radius: 0 !important;
}

.accordion-tag p {
  line-height: 1.5;
  font-size: 13px!important;
}

.accordion-tag a {
  color: #131415 !important;
}

.accordion-tag span.block-title {
    float: left;
    font-weight: 600;
}

.accordion-tag i {
    float: right;
    font-weight: 600;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.plan-tab {
  padding-top: 45px;
  padding-bottom: 20px;
  text-align: center;
}

.plan-tab ul {
  list-style: none;
}

 .plan-tab li {
  display: inline-block;
  padding: 4px 1.5rem;
  cursor: pointer;
  padding: 8px 18px;
}

 .plan-tab li.active {
   color: #070763;
   border-bottom: 1px solid #070763;
}

 nav.plan-tab.mbt-35 {
  text-align: center;
}

 .plan-tab ul {
    list-style: none;
    margin: 0 0 25px 0px;
    padding: 0;
    display: inline-block;
}

 .plan-tab ul li {
    float: left;
    border: 1px solid #070763;
    font-size: 12px;
    font-weight: bold;
}

 .plan-tab ul li:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: none;
}

.plan-tab ul li:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

 .plan-tab li.active {
    color: #fff;
    background: #070763;
    border: 1px solid #070763;
    padding: 8px 18px;
}

/* Pricing Page Css End */

.rsc {
    position: fixed;
    right: 17px;
    bottom: 17px;
    z-index: 1;
}

video#video-background {
    object-fit: cover;
}

/* Comming soon css */
.comingsoonPage {
	 height: auto;
}
 .comingsoonPage p, .comingsoonPage ul, .comingsoonPage li {
	 color: gray;
}
 .comingsoonPage .coming-soon-container {
	 background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.pexels.com/photos/2812697/pexels-photo-2812697.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260) no-repeat center center/cover;
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
}
 .comingsoonPage .coming-soon-container .row {
	 transform: translatey(50%);
}
 .comingsoonPage .coming-soon-container .row .mainText {
	 font-size: 90px;
	 color: #fff;
	 line-height: 1.1;
	 font-weight: 700;
}
 .comingsoonPage .coming-soon-container .row .secondaryText {
	 font-size: 20px;
	 color: #ccc;
	 line-height: 1.2;
}
 .comingsoonPage .coming-soon-container .row .form-container {
	 margin-top: 50px;
}
 .comingsoonPage .coming-soon-container .row .form-container form {
	 width: 40%;
	 position: relative;
	 background-color: transparent;
	 height: 60px;
	 padding-right: 80px;
	 background-color: rgba(255, 255, 255, 0.1);
}
 .comingsoonPage .coming-soon-container .row .form-container form input {
	 margin: 0px;
	 display: block;
	 width: 100%;
	 height: 100%;
	 padding: 0 15px 0 25px;
	 background-color: transparent;
	 font-size: 16px;
	 border: none;
	 font-weight: 600;
	 color: #fff;
}
 .comingsoonPage .coming-soon-container .row .form-container form button {
	 margin: 0px;
	 width: 80px;
	 height: 100%;
	 position: absolute;
	 right: 0px;
	 top: 0px;
	 font-size: 14px;
	 color: #fff;
	 background-color: transparent;
	 border: 0px;
}
 .comingsoonPage .coming-soon-container .row a.back {
	 background-color: rgba(255, 255, 255, 0.1);
	 height: 50px;
	 color: #fff;
	 font-size: 14px;
	 margin-top: 30px;
	 text-decoration: none;
	 line-height: 50px;
	 width: 200px;
	 text-align: center;
}

/* Comming soon end css */

@media (min-width: 992px) {
.ticker-main-div .col-lg-2 {
    flex: 0 0 19.66667% !important;
    max-width: 19.66667% !important;
}

.footer .col-wd-4 {
  flex: 0 0 18% !important;
  max-width: 18% !important;
}

}

.plan-tab li a{
  color:  #211f72!important;
}


.plan-tab li.active a{
  color: white !important;
}

 .plan-tab ul li:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: none;
}

.plan-tab ul li:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

 .plan-tab li.active {
    color: #fff;
    background: #070763;
    border: 1px solid #070763;
    padding: 8px 18px;
}
/* Blog Page */
.blog-detail-image .img-fluid{
  width: 38rem!important;
  height: 39rem!important;
}
@media (max-width:600px){
  .blog-detail-image .img-fluid{
    width: 100%!important;
    height: 100%!important;
  }
  .blog-content{
    margin-right: 0rem!important;
  }
}
.blog-content-div{
  background: #fff!important;
}
.blog-list-card{
  min-height: 350px!important;
}

.blog-image-div{
margin: 3% 0%;
}
.BlogShare{
  padding-right: 0.5rem;
  display: grid!important;
}
.blog-content{
margin-right: 3.8em;
  margin-bottom: 3em;
}
.blog-content .blogPara{
  margin: 2rem 0rem;
}
.blog-content strong{
  color: #182026;
}
.htmlspan p{
  margin: 0rem 0rem!important;
}
.htmlspan li{
  margin: 0.7rem 0rem;
}
.htmlspan strong{
  font-size: 15px;
}
.blogPara .paraImage{
  width: 32.4rem;
  height: 32.4rem;
}
@media screen and (max-width: 600px) {
  .blogPara .paraImage{ 
    max-width: 100%;
    height: auto;
  }
   
} 
@media   (max-width:900px) {
  /* .BlogShare {
    display: block !important;
    text-align: center;
  }
  .pad-2{
    padding-left: 0.25rem!important;
    padding-right: 0.25rem!important;
  }
   */
   .mt-tab-5{
    margin-top: 3rem!important;
   }
   .first{
    order: 1;
   }
   .second{
    order: 2;
   }
}
@media screen and (min-width:601px )and (max-width: 900px) {
  .blogPara .paraImage{ 
    width: 30rem;
    height: 30rem;
  }
  .blogPara .para-layout-img{ 
    width: 30rem;
    height: 30rem;
  }
}
.blog-content .border{
  border: 1px solid #dee2e6 !important;
}
.fontsize{
  font-size: 2.6rem!important;
}
.blogShareIcons .fa
{
  color: #090763;
  font-size: 25px;

}
.blogShareIcons .fa-link{
  cursor: pointer;
}
.blog-heading{
  height: auto;
  padding: 8em 0;
}
.site-page__heading {
 color:#211f72;
  font-size: 2.188rem;
  font-weight: bolder;
  letter-spacing: -0.13px;
  text-transform: uppercase;
}
.background-header{
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 0;
}
.blog-img-card-top{
  height: 8.75rem;
}
.zindex{
  z-index: 2;
}
.back-icon{
  color: #090763;
  font-size: 1.031rem;
  cursor: pointer;
}
.back-icon span{
  font-family: "Raleway", sans-serif;
  font-size: 0.9rem;
}
.padding-top{
  padding-top: 5rem;
}
.padding-right{
  padding-right: 1.9rem!important;
}
.margin-top {
  margin-top: 2rem;
}
.display{
display: none;
}
@media (min-width:600px) {
.margin-top{
  margin-top: 16rem;
}
.display{
display: unset;
}
} 
/*blog card*/
.blog-card .blog-card-content{
  padding-top :1.25rem!important;
  padding-bottom: 1.25rem!important;
  padding-left: 0.5rem!important;
  padding-right: 0.5rem!important;
}
.blog-card .blog-image{
margin-bottom: 1rem;
height:10.8rem
}
.blog-card .blog-title{
  font-size: 17px;
}
.blog-card .blog-desc{
  font-size: 14px;
}
.blog-card .link-blog{
  text-decoration: none!important;
}
/*Not Found Page*/
#notFound .notFoundHeading{
  font-size: 13.5rem;
  color: #090763;
  font-weight: bolder;
}
#notFound .notFoundSubheading{
  font-size: 18.5px;
  opacity: 0.8;
  font-family: Montserrat!important
}
#notFound  span{
      font-weight: 700;
      font-size: 20px;
      color: #090763;
  }

#notFound.backButton:hover{
  font-size: 14px!important;
      font-weight: 500!important;
  }

  #notFound .mt6rem{
  margin-top: 13rem;
  margin-bottom: 10rem;
}
#section-down .border-insight{
border:1px solid #d1d1d1;
}
.hovera:hover{
  font-size: 14px;
  font-weight: 600;
  text-decoration: none!important;
}
.mb-custom{
  margin-bottom: 0.9375rem!important;
}
.CopyBtn{
  height: 23px;
  width: 23px;
}
.py-20{
  padding-top: 20px;
  padding-bottom: 20px;
}
.resourceSubHeading{
  font-size:2.188rem;
  font-weight: bold;
  color: #182026!important;
}
.borders{
  border: 1px solid gray;
}
.custom-delete .fa{
color: #dc3545;
  font-size: 22px;
  cursor: pointer;
}
.custom-add{
  color: #211f72;
  font-size: 25px;
  cursor: pointer;
}
.main-container1{
  padding: 90px 15px 50px 15px;
  min-height:90vh;
}
.main-container2{
  padding: 60px 15px 50px 15px;
  min-height:83vh;
}
.pt-150{
  padding-top: 150px;
}
.main-container12{
  padding: 0px 15px 50px 15px;
}
.minheight85vh{
  min-height: 77vh;
}
.pdf-container{
  height: 48rem;
  width: 43rem;
  margin-left: auto;
  margin-right: auto;
}
.purpleBadge {
  background-color: #8A6FF9;
  position: relative;
  top: -12px;
  left: -17px;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
}
.purpleBadge2 {
  background-color:  #090763;
  position: absolute;
  top: 3px;
  left: 5px;
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}
@media (max-width: 1023px){
  /* .purpleBadge {
    position: absolute;
    left: -20px;
    top: -2px;
    color: red;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    rotate: -38deg;
  } */
  .pdf-container{
    height: 25rem;
    width: 20rem;
    margin-left: auto ;
    margin-right: auto;
  }
}
.posrel{
  position: relative;
}
.no-arrow {
  appearance: textfield;
   }
   .no-arrow::-webkit-inner-spin-button {
     display: none;
   }
   .no-arrow::-webkit-outer-spin-button,
   .no-arrow::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }