@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
* {
  box-sizing: border-box;
}
form.imagebox input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.framebox {
  text-align: center;
}
iframe {
  width: 1050px;
  margin: 0 auto !important;
  float: none !important;
}
@media (min-width:1401px){
  .shiftleft {
    position: relative;
    left: -32%;
  }
  }
@media (min-width:992px) and (max-width:1400px){
.shiftleft {
  position: relative;
  left: -22%;
}
}
img#profilepage {
  border-radius: 50%;
}
form.imagebox {
  display: inline-block;
  position: relative;
}
.imagebox > img {
  opacity: 1;
  background: #364fcc;
  padding: 7px;
  border-radius: 50%;
  position: absolute;
  right: 14px;
  bottom: 8px;
}
.resourcesPage .react-bootstrap-table table.table thead tr :nth-child(1) span{
  width:auto;
  position: relative;
}
.resourcesPage .react-bootstrap-table table.table thead tr th:nth-child(1){
  width:70% !important;
}
@media print {
 body {
    display: none;
  }
 
}
body {
  margin: 0;
  font-family: $site-font-family;
  line-height: 1.4;
  color: $dark-purple;
  background: $bg-color;
  font-size: 14px;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.content-height svg{

}
img.eventimg {
  width: 18px;
}
img.eventimg {
  padding: 9px;
  background: $light-purple;
  border-radius: 50%;
  width: 30px;
}
.nav img.icon {
  width: 18px;
}

.sidebar-icon-only .main-panel {
  width: calc(100% - 70px) !important;
}

#root {
  overflow: hidden;
}
.country-info p{
  font-size: 13px;
}
.peanlty--graph .radio + label{
  // padding-left: 34px !important;
  width:auto !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  margin:0 5px;
}
a.profilelink {
  color: #8e94a8;
  text-decoration: none;
}
.card-icon-indicator.bg-inverse-icon-primary a img {
  width: 2rem;
  height: auto !important;
}

.card-icon-indicator.bg-inverse-icon-primary a {
  position: absolute;
  left: 0;
  top: 0;
}
.card-icon-indicator.bg-inverse-icon-primary{
  position: relative;
}
.aboutPage .card-body > .nav-tabs > a {
  background: rgba(128, 125, 248, 0.8) !important;
  color: #fff !important;
  padding: 39px 50px !important;
  text-align: center;
  border-radius: 10px !important;
  border: 3px solid #fff !important;
  font-weight: bold;
  font-size: 16px !important;
  margin-right: 0.5rem !important;

  &:hover {
    background: rgb(128, 125, 248) !important;
  }

  &.active{
    color: rgb(128, 125, 248)  !important;
    background: #FFF !important;
    box-shadow: 0 4px 8px rgba(128, 125, 248,0.25) !important;
  }
}

nav.events-detail-tab.nav.card-header-tabs.nav-tabs {
  margin-bottom: 1rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

nav.events-detail-tab.nav.card-header-tabs.nav-tabs a.active {
  // border-color: #211f72 !important;
  // background: #211f72!important;
  // color: #FFF  !important;
  border-bottom: 3px solid #211f72 !important;
}
nav.events-detail-tab.nav.card-header-tabs.nav-tabs a {
  margin-right: 1rem;
  flex: 1;
  padding: 0.5rem 0!important;
  text-align: center;
  border-radius: 0 !important;
  border: 3px solid #fff !important;
  font-weight: 400;
  font-size: 14px !important;
  background: #FFF !important;
  color:  #211f72  !important;
  border: 0 !important;
  border-bottom: 3px solid transparent !important;
}
.video-contaier {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.alert-success {
  color: #009b9c;
  background-color: rgba(0, 204, 205, 0.2);
  border-color: #00bcbd;
  position: absolute;
  top: -47px;
}
.authentication-modal .auth-tabs div{
  background-color: #fff !important;
  color:#000 !important;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.authentication-modal .auth-tabs div.active {
  color: #fff !important;
  background-color: transparent;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  background: #2a3f54 !important;
}
.dashboard-wrapper .card.latest-container .card-body .card-statistics .mobile-news .img-thumbnail{
  width:100% !important;
  height:100px !important;
}
.landing-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.bg-color {
  background-color: $card-bg-color;
}

.text-color {
  color: $light;
}

.text-color-primary {
  color: $text-color;
}

.row {
  margin: 0;
}

.landing-box {
  position: absolute;
  display: flex;
  top: 25%;
  width: 30%;
  height: 55%;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  flex-direction: column;
  justify-content: center;
  right: 0%;
  left: 60%;
  border-radius: 1rem;

  .landing-logo {
    width: 150px;
    margin: 0 auto;
  }

  .landing-logo-tagline {
    font-size: 20px;
    text-align: center;
    padding: 15px 0px;
    color: #000000;
    font-weight: 600;
  }

  .landing-button {
    text-decoration: none;
    font-size: 20px;
    color: #ffffff;
    padding: 5px 50px;
    margin: 0 auto 0;
    background-color: #73879c;
    box-shadow: 4px 14px 20px rgba(0, 0, 0, 0.5);
  }

  .footer-container {
    padding: 13px 60px;

    ul {
      margin: 0;
    }

    .zigram-copyright {
      line-height: 1.9;
    }
  }
}

@media only screen and (max-width: 568px) {
  .row .col-sm-12 {
    margin-top: 10px;
  }

  .landing-box {
    display: flex;
    top: 25%;
    width: 100%;
    height: 50%;
    flex-direction: column;
    left: 0;
    border-radius: 15px;
  }

  .landinglogotagline {
    font-size: $font-size-regular;
  }

  .landingbutton {
    width: 120px;
    font-size: $font-size-regular;
    border-radius: 15px;
    padding: 6px 20px;
  }

  .landing-button {
    text-decoration: none;
    font-size: 24px;
    color: #ffffff;
    padding: 5px 50px;
    margin: 0 auto 0;
    background-color: #73879c;
    box-shadow: 4px 14px 20px rgba(0, 0, 0, 0.5);
  }

  .left-sider {
    display: none;
  }

  .left-sider-only {
    display: flex;

    .navigation {
      span {
        font-size: $font-size-regular;
      }
    }
  }

  .right-container-only {
    margin-left: 200px;
  }

  .right-container {
    width: 100%;
    margin-left: 0;

    .card-col {
      h4 {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    div.content-height {
      height: 600px;

    }

    .ribbon-info h3 {
      font-size: $font-size-regular;
    }

    div.graph {
      height: unset;
    }

    .latest-container>.card-body {
      padding: 0;
    }

    .grid-margin .card-ribbon .row {
      justify-content: space-around;
    }
  }

  .footer {
    width: 100%;
    margin-left: 0;

    .privacy-policy {
      display: flex;
      justify-content: center;

      ul {
        padding-inline-start: 0;

        li {
          padding: 0 10px;
        }
      }
    }

    div.zigram {
      text-align: center !important;
    }
  }

  .footer-only {
    margin-left: 200px;
  }

  .overview-details {
    padding: 10px;
    border: 1px solid $primary-color;
    border-radius: 15px;
  }

  .mobile-news {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.till-date-menu {
  width: 100%;
  text-align: center;
}

.font-small {
  font-size: $font-size-sm;
}

.right-container {
  .till-date button {
    padding: 0.2rem 0.75rem;
  }

  .card-border {
    border: 1px solid $border-color;
  }

  .content-height {
    height: 550px;
  }

  .card-col:nth-child(1) {
    margin-left: 5px;
  }

  .latest-container {
    height: 500px;

    .card-title {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $light;
      border-bottom: 1px solid $border-color;
    }

    .recent-card-border {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 3px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .card-statistics {
    color: $text-color;
    border-radius: 15px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.07);
    border: solid 0.5px rgba(216, 216, 216, 0.65);
    background-color: #d8d8d8;
  }

  .country-dropdown-btn {
    padding: 5px 50px;
    background: $dark-purple !important;
  }

  .country-dropdown {
    overflow: hidden auto;
    height: 250px;
    width: 100%;
  }

  .graph, .peanlty--graph {
    height: 440px;

    .radio:checked + label:before {
      display: none!important;
    }
  }

  .gradient-bullet-list {
    list-style: none;
  }

  .graph {
    .card-title {
      border-bottom: 1px solid $border-color;
    }
  }

  .graphs-title {
    display: inline-block;
  }

  .nav-tabs .nav-link {
    border: 1px solid $border-color;
    margin-left: 0.1rem;
    font-size: $font-size-regular;
  }

  .country-image-slider {
    height: 350px;

    .carousel-inner {
      height: 100%;
    }
  }

  .country-event-table {
    font-size: $font-size-sm;
    color: $text-color;

    th {
      background: #e9e9f1 !important;
      color: #102563 !important;
      font-weight: 900 !important;
    }

    thead tr :nth-child(1) {
      width: 15%;
    }

    thead tr :nth-child(2) {
      width: 25%;
    }

    thead tr :nth-child(3) {
      width: 20%;
    }

    .country-event-table-header {
      cursor: pointer;
    }

    .overview-details {
      padding: 5px;
      border: 1px solid $primary-color;
    }
  }

  .country-economic-table {
    font-size: $font-size-sm;
    color: $text-color;

    thead tr :nth-child(1) {
      width: 20%;
    }

    thead tr :nth-child(2) {
      width: 15%;
    }

    thead tr :nth-child(3) {
      width: 15%;
    }

    thead tr :nth-child(4) {
      width: 15%;
    }
  }
}

.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 60px;
  background: $landing-page-box;
  color: $dark;
  opacity: 0;
  transition: opacity 0.5s;
}

.footer-container:hover {
  opacity: 1;
}

.privacy-policy {
  ul {
    list-style: none;

    li {
      display: inline-block;
      padding: 0px 20px;

      a {
        color: $dark;
      }
    }

    .btn-link {
      text-decoration: none;
    }
  }
}

.zigram-footer {
  color: $dark;
}

.input-group input {
  height: unset;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.btn-primary {
  // background-color: $primary-color;
  background-color: $dark-purple;
  border: 1px solid $primary-color;
}

.authentication-modal {
  .modal-header {
    border-bottom: none;
  }
}

.hamburger {
  font-size: 30px;
}

.fa-events {
  background: #73879c;
  border-radius: 100%;
  border: 1px solid #73879c;
}

.nav-pills .nav-item {
  border: 1px solid;
  margin-right: 5px;
  border-radius: 5px;
}

.new-image {
  margin-left: -15px;
}

.img-thumbnail {
  max-width: unset;
}


// Navbar

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group {
  position: relative;
  width: 100%;

  &:hover {
    .form-control {
      width: 260px;
    }
  }

  .form-control {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    height: 35px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 0px;
    width: 50px;
    transition: all ease 0.5s;

    &:focus {
      width: 260px;
    }
  }

  .input-group-append {
    background: transparent;
    border: 0;
    color: #ffffff;
    padding: 0.4rem 1rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 3;

    .input-group-text {
      padding: 0px;

      i {
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .input-group-text:hover+.form-control {
    display: none;
  }
}


// Dashboard

.content-wrapper {
  padding: 10px 10px;
}

.sidebar .sidebar-brand-wrapper .sidebar-brand {
  padding: 0.25rem 0 0.25rem 40px;

  img {
    width: auto;
    max-width: 100%;
    height: auto !important;
    margin: auto;
    vertical-align: middle;
  }
}

.stretch-card.col-md-6 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}


.dashboard-wrapper {
  .country-ribon {
    margin-bottom: 10px;

    .dropdown-menu {
      top: 0px !important;
      width: 250px;
      // height: 300px;
      overflow: hidden;
      overflow-y: auto;
      margin: 0;
      height: 400px;
      padding: 0.5 0;

      input {
        width: 85%;
        margin: 8px 20px;
        padding: 5px 10px;
      }
    }
  }

  .density-container {
    display: flex;

    .density-strip {
      width: 20%;
      background: linear-gradient(to right, #ffffff, #807df8);
    }
  }

  .content-height {
    height: 550px;
    .card-title{
      position: absolute;
    top: 0;
    left: 0;
    width: 100%;
      }
    &.card {
      .card-title.country-info-heading {
        color: #fff;
        padding: 10px !important;
        font-size: 15px;
      }

      .country-info {
        overflow: hidden;
        overflow-y: scroll;
      }
    }
  }



  .latest--events {
    a {
      text-decoration: none;
      color: $dark-purple;

      .card {
        .card-body {
          padding: 10px 10px;
        }
      }
    }

    .country-flag {
      outline: 1px solid rgba(0,0,0,0.25);
      outline-offset: -1px;
      display: block;
      width: 35px;
      height: 23px;

      img {
        width: 100% !important;
        position: relative;
        top: -6px;
      }
    }
  }

  .card.latest-container {
    overflow: hidden;
    height: auto;


    .card-body {
      padding: 10px 10px;

      .card-statistics {
        min-height: 170px;
        .mobile-news {
          .img-thumbnail {
            max-width: unset;
            width: 110px;
            height: 200px;
            object-fit: cover;
            border: 0;
          }
        }

        h6, h5 {
          font-size: 18px;
          color: $dark-purple;
          font-weight: 600;
        }

        h6 {
          font-size: 16px;
        }
      }
    }
  }
}

.peanlty--graph {
  .radio {
    display: none;
  }

  .radio+label {
    position: relative;
    display: block;
    // padding-left: 1.5em;
    padding-left: 12px;
    cursor: pointer;
    width: 100px;
    float: right;
    color: $dark-purple !important;
    background: #fff;
    border-color:$dark-purple;
    border-radius: 5px !important;
    box-shadow: 0 2px 11px 2px #211f7221;
  }

  .radio+label:before {
    content: '\F12C';
    font-family: 'Material Design Icons';
    // display: inline-block;
    display: none !important;
    vertical-align: top;
    line-height: 1em;
    width: 1em;
    height: 1em;
    margin-right: 0.3em;
    text-align: center;
    color: $dark-purple !important;
    background: #fff;
    position: absolute;
    font-size: 21px;
    font-weight: 900;
    top: 5px;
    display: none;
    left: 10px;
  }

  .radio:checked+label {
    display: block;
    color: #FFF !important;
    background: $dark-purple !important;
  }

  .radio:checked+label:before {
    padding-top: 3px;
    display: block;
    color: #FFF !important;
    background: $dark-purple !important;
  }
}

.dashboard {

  .stretch-card {
    padding-right: 5px !important;
    padding-left: 5px !important;

    .color-card {
      width: 200px;
      cursor: pointer;

      .color-card-head {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        height: 45px;
      }


    }

    .card-footer-bg {
      background: #fff;
      text-align: center;
      border-radius: 30px;
      padding: 4px;
    }

    // >.card.bg-warning {
    //   background-color: #71c7ec !important;
    // }

    // .bg-inverse-icon-warning {
    //   background: #66adcc;
    //   border-color: #66adcc;
    // }

    // .card.bg-danger {
    //   background-color: #189ad3 !important;
    // }

    // .bg-inverse-icon-danger {
    //   background: #278ab5;
    //   border-color: #278ab5;
    // }

    // .card.bg-primary {
    //   background-color: #005073 !important;
    // }

    // .bg-inverse-icon-primary {
    //   background: #073a50;
    //   border-color: #073a50;
    // }
  }

  .card .card-body .card-icon-indicator {
    width: 60px;
    height: 40px;
    font-size: 24px!important;
    color: $light-purple;
    border-radius: 50px;
    text-align: center;
    line-height: 2.2;
  }

}

// Events

.event {
  .table {

    th,
    td {
      vertical-align: middle;
      font-size: 14px;
      line-height: 1.5;
      white-space: unset;
      padding: 14px 0 14px 25px;
    }
  }

  .country-event-table {
    font-size: 14px;
    color: #73879c;

    thead tr :nth-child(1) {
      width: 6%;
    }

    thead tr :nth-child(2) {
      width: 7%;
    }

    thead tr :nth-child(3) {
      width: 25%;
    }

    thead tr :nth-child(4) {
      width: 20%;
    }

    a {
      color: #344252;
      text-decoration: none;
    }
  }

  .map {
    width: 25px;
    height: 200px !important;
  }


  .event-ribbon {

    h2 {
      font-size: 20px;
    }

    p {
      color: white;
    }

    .stretch-card>.card.bg-warning {
      background-color: #71c7ec !important;
    }

    .bg-inverse-icon-warning {
      background: #66adcc;
      border-color: #66adcc;
    }

    // .stretch-card>.card.bg-danger {
    //   background-color: #189ad3 !important;
    // }

    .bg-inverse-icon-danger {
      background: #278ab5;
      border-color: #278ab5;
    }

    .stretch-card>.card.bg-primary {
      background-color: #1f7398 !important;
    }

    .bg-inverse-icon-primary {
      background: #196080;
      border-color: #196080;
    }

    .stretch-card>.card.bg-warning-new {
      background-color: #005073 !important;
    }

    .bg-inverse-icon-warning-new {
      background: #073a50;
      border-color: #073a50;
    }

    .card .card-body .card-icon-indicator {
      width: 45px;
      height: 45px;
      font-size: 24px!important;
      color: $light-purple;
      border-radius: 50px;
      text-align: center;
      line-height: 2.2;
    }
  }

  .nav-tabs {
    margin: 0 !important;
    width: 100%;

    .nav-link.active,
    .nav-item.show .nav-link {
      color: #fff;
      background: #496576 !important;
      border-color: #ebedf2 #ebedf2 #ffffff;
      flex: 1;

      i {
        color: #fff;
      }
    }
  }

  .tab-content {
    border: 1px solid #ebedf2;
    border-top: 0;
    padding: 2rem 1rem;
    text-align: justify;
    width: 100%;
  }
}


.privacy {

  .title {
    margin-top: 30px;
  }

  .x_content {
    margin-top: 30px;
  }
}

.profilePage {
  .alert  {
margin-top: 7px;
  }
}
.profilePage {
  .progress-bar.bg-primary {
    background: #364fcc !important;
}
  .Shape-Copy{
    border-radius: 4px;
  background-color: #364fcc;
  padding:5px 5px;
  margin:20px 0;
  cursor: pointer;
  }
  .Shape-update{
    border-radius: 4px;
    background-color: #211f72;
    border: 1px solid #2a3f54;
  padding:8px 10px;
  margin:0px 0;
  cursor: pointer;
  }
  .bitmap {
    width: 115px;
    height: 115px;
    img{
      border-radius: 50%;
      width: 100%;
      height: 100%;
      border: solid 2.9px #8380f8;
    }

  }
  input{
    max-width: 500px;
    border: 1px solid #e2e4e5;
  }
  .gapp{
    padding: 20px 5px 30px;
  }
  label{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    color: #514eab;
  }
  .invitepeople{
    font-family: Montserrat;
  font-size: 12.8px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.91px;
  text-align: center;
  color: #ffffff;
  }
  .alert  {
margin-top: 7px;
  }

  .professione-copy {
    width: 222px;
    height: 19px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
   // margin-bottom: 5px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: #131415;
  }
  .professione {

    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 5px;
    text-transform: capitalize;
    line-height: normal;
    letter-spacing: normal;
    color: #131415;
  }
  .Nome,.name {

    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131415;
  }

  .rectangle {
    padding:10px;
    border-radius: 14.8px;
    border: solid 0.5px rgba(189, 189, 189, 0.65);
    background-color: #ffffff;
    .num{
      opacity: 0.9;
  font-family: Montserrat;
  font-size: 26.3px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #364fcc;
    }
  }
  .pi {
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #364fcc;
  }
}

// start
.Professione-Copy {
  width: 222px;
  height: 19px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #131415;
}
.Professione {

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #131415;
}
.Nome {

  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #131415;
}
// end

.countryPage {
  .country-ribon {
    margin-bottom: 10px;

    .dropdown-menu {
      top: 0px !important;
      width: 250px;
      height: 300px;
      overflow: hidden;
      overflow-y: auto;
      margin: 0;
      padding: 0;

      input {
        width: 85%;
        margin: 8px 20px;
        padding: 5px 10px;
      }
    }
  }

  .events-detail-tab {
    a {
      flex: -0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
  }

  .card-statistics {
    .nav-pills {
      .nav-item {

    border-radius: 0;
    border: 0;
    padding: 0;
       background: #f6f8fa;
    color: #000000;
    margin: 0;
    flex: 1 1;

    a {
      background: #f6f8fa;
      color: #000000;
      border-radius: 0;
      border: 1px solid #adb2b5;
      padding: .75rem 0.5rem;
      font-size: 0.850rem;
      flex: 1 1;
      text-align: left;
      margin: 0;

      &.active {
        color: #ffff;
        background: #112563 !important;

        i {
          color: #fff !important;
        }
      }
    }
      }
    }
  }


  .stretch-card {
    padding-right: 5px !important;
    padding-left: 5px !important;

    .color-card {
      width: 200px;

      .color-card-head {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        height: 45px;
      }
    }

    // >.card.bg-warning {
    //   background-color: #71c7ec !important;
    // }

    // .bg-inverse-icon-warning {
    //   background: #66adcc;
    //   border-color: #66adcc;
    // }

    // >.card.bg-danger {
    //   background-color: #189ad3 !important;
    // }

    // .bg-inverse-icon-danger {
    //   background: #278ab5;
    //   border-color: #278ab5;
    // }

    // >.card.bg-primary {
    //   background-color: #1f7398 !important;
    // }

    // .bg-inverse-icon-primary {
    //   background: #196080;
    //   border-color: #196080;
    // }

    // >.card.bg-warning-new {
    //   background-color: #005073 !important;
    // }

    // .bg-inverse-icon-warning-new {
    //   background: #073a50;
    //   border-color: #073a50;
    // }
  }


  .card .card-body .card-icon-indicator {
    width: 60px;
    height: 40px;
    font-size: 24px!important;
    color: $light-purple;
    border-radius: 50px;
    text-align: center;
    line-height: 2.2;
  }

  .col-xl-2 {
    flex: 0 0 20.00000%;
    max-width: 20.00000%;
  }

  .m-auto {
    margin-bottom: 25px !important;
  }

  .table th,
  .table td {
    padding: 14px 25px 14px 25px;
  }

  .country-indice-card.card {
    height: 100%;

    .card-title {
      font-size: 22px;
    }
  }

  .country-image-slider {
    border: 1px solid #000;
  }

  .country-economic-table thead {
    th {
      font-weight: bold;
    }

    tr :nth-child(1) {
      width: 50%;
    }


    tr :nth-child(2),
    tr :nth-child(3),
    tr :nth-child(4),
      tr :nth-child(5),
      tr :nth-child(6) {
      text-align: center;
    }
  }
}

.eventsPage {
min-height: 90vh;

.card-body.event-table-card {
  min-height: 250px;
}

  select.form-control:not([size]):not([multiple]),
  .swal2-modal .swal2-content select.swal2-input:not([size]):not([multiple]) {
    height: auto !important;
  }

  .submit-btn {
    margin-top: 20px;
  }

  .eventErrorMsg {
font-size: 15px;
color: red;
  }
}

.searchPage {
  min-height: 90vh;
  .nav-pills .nav-item {
   border: 0;

   a {
    background: #FFF;
    color: #112563;
    border: 2px solid #112563;

     &.active {
       background: #112563;
       color: #FFF;
       border: 2px solid #112563;
     }
   }
}
  .search-table {
    thead td {
      font-weight: 900;
      color: rgb(16, 37, 99);
      background: rgb(233, 233, 241);
    }

    th,
    td {
      white-space: unset;
      line-height: 1.5;
      padding: 14px 20px 14px 25px;
      &.name {
        width: 600px;
      }
    }
  }
}

// .comingsoonPage {
//   height:auto;
//   p, ul, li{
//     color: gray;
//   }
//
//   .coming-soon-container {
//     background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.pexels.com/photos/2812697/pexels-photo-2812697.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260) no-repeat center center/cover;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//
//     .row {
//       transform: translatey(50%);
//
//       .mainText {
//         font-size: 90px;
//         color: #fff;
//         line-height: 1.1;
//         font-weight: 700;
//       }
//
//       .secondaryText {
//         font-size: 20px;
//         color: #ccc;
//         line-height: 1.2;
//       }
//
//       .form-container {
//         margin-top: 50px;
//
//         form {
//           width: 40%;
//           position: relative;
//           background-color: transparent;
//           height: 60px;
//           padding-right: 80px;
//           background-color: rgba(255, 255, 255, 0.1);
//
//           input {
//             margin: 0px;
//             display: block;
//             width: 100%;
//             height: 100%;
//             padding: 0 15px 0 25px;
//             background-color: transparent;
//             font-size: 16px;
//             border: none;
//             font-weight: 600;
//             color: #ffffff;
//           }
//
//           button {
//             margin: 0px;
//             width: 80px;
//             height: 100%;
//             position: absolute;
//             right: 0px;
//             top: 0px;
//             font-size: 14px;
//             color: #ffffff;
//             background-color: transparent;
//             border: 0px;
//           }
//         }
//       }
//
//       a.back {
//         background-color: rgba(255, 255, 255, 0.1);
//         height: 50px;
//         color: #fff;
//         font-size: 14px;
//         margin-top: 30px;
//         text-decoration: none;
//         line-height: 50px;
//         width: 200px;
//         text-align: center;
//       }
//     }
//   }
// }

.aboutPage {
  .nav.nav-tabs {
    display: flex;
    padding: 10px 0 0;
    padding-bottom: 1rem;
    border-bottom: 0;

    .nav-item.nav-link {
     color: #fff;
    background-color: #1d2c3b;
    font-size: 0.875rem;
    line-height: 1.71;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    margin-right: 3px;
    border: 1px solid #adb2b5;
    padding: .5rem 1rem;
    border-radius: 0.25rem;


      &.active {
        background-color: #192632;
        border-color: #151f2a;
      }
    }
  }
  .tab-content {
    border: 1px solid #adb2b5 !important;
    border-color: #d8d8d8 !important;
    border-radius: 0.25rem !important;
    padding: 15px;
  }


  .nav.nav-pills {
    display: flex;
    padding: 10px 0 0;
    width: 100%;

    .nav-item {
      background: #fff;
    border: 0px solid #fff;
    margin: 0 !important;
    flex: 1 1;


    a {
      background: #f6f8fa;
      color: #000000;
      border-radius: 0;
      border: 1px solid #adb2b5;
      padding: .75rem 0.5rem;
      font-size: 0.730rem;
      flex: 1 1;
      text-align: left;

      &.active {
        color: #fff;
        background: #496576 !important;
        border-color: #ebedf2 #ebedf2 #ffffff #ebedf2 #ebedf2 #ffffff #ffffff;
        margin: 0;
      }
    }


    }
  }
}
.resourcesPage .filter-section .nav-pills {
  border-bottom: 0;
  position: absolute;
  right: 0;
  top: -79px;
}
.resourcesPage {
  .filter-section {
    position: relative;
}
  .page-header, .card{
    margin: 0 !important;
  }
  .search-container {
    border-bottom: 1px solid #c5c5c5;
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    span {
      line-height: 2;
      font-size: 15px;
    }

    .form-control {
      width: 160px;
    margin-left: 10px;
    }
  }
  .filter-section {
    .nav-pills {
      border-bottom: 0;

      .nav-item {
        border: 0;
        margin-right: 0;

        .btn-primary.active {
          box-shadow: 0 0 0 0 rgba(42, 63, 84, 0.5);
        }
      }
    }

    .sub-filter {
      .range-filter {
        display: flex;
        padding: 10px 0 0;

        .range-filter-item {
          background: #f6f8fa;
          color: #000000;
          border-radius: 0;
          border: 1px solid #adb2b5;
          padding: .75rem 1.5rem;
          font-size: 0.875rem;
          flex: 1;

          &.active {
            color: #ffff;
    background: #112563 !important;
            border-color: #ebedf2 #ebedf2 #ffffff #ebedf2 #ebedf2 #ffffff #ffffff;
          }
        }
      }
    }



  }

  .table-regulator {
    border-color: #d8d8d8 !important;
    border-radius: 0.25rem !important;
    margin-top:20px;

    .d-flex.align-items-center {
margin-top: 10px;

    }
  }

  .regulation-table {

    .table-header {
      text-align: center;
      padding: 1rem 2rem 0 2rem;
      font-weight: bold;
    }

    .card-wrapper {
      margin: 12px 0 12px 12px;
    }

    .data-cards {
      display: flex;
      margin: 8px;
      border: 1px solid;
      border-radius: 5px;
      padding: 1rem;
    }

    .card-item {
      font-size: 14px;
      text-align: center;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 15px !important;
    align-items: flex-end;
    margin-bottom: 15px !important;
    z-index: 1000000;
    position: relative;
}

  .react-bootstrap-table table.table {
    table-layout: fixed !important;
    overflow: hidden;

    thead th {
      padding: 25px !important;
    }

    thead tr :nth-child(1) {
      width: auto;
    }

    tbody tr :nth-child(3) {
      text-align: center;
      padding: 14px 25px 14px 25px;
    }
    tbody tr :nth-child(4) {
      text-align: center;
      padding: 14px 25px 14px 25px;
    }




    td {
      white-space: unset;
      line-height: 1.5;
  }
}
}

// Footer

.footer {
  background: #000;
  z-index: 200;
  padding: 15px 0;
  color: gray;
  font-size: 12px;
p{
  color:gray;
}




      a,
      button {
        color: gray;
        font-size: 12px;

        &:hover {
          text-decoration: none;
        }

  }

  .zigram-copyright {
    color: gray;
    font-size: 12px;

    a {
      color: gray;
      font-size: 14px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.footer-container {
  padding: 12px 60px;

  .privacy-policy ul {
    padding: 0;
    margin: 0;
  }

  .zigram-copyright {
    line-height: 25px;
  }

}

.countries-list-table {
  tbody  tr{
    border-top: 1px solid #ebedf2;
    // background: red !important;

    td:first-child {
      font-weight: 600;
    }
  }

  tbody  tr:first-child, td{
    border: none;
  }


}

.card-statistics {
  padding: 10px 10px;
}

.pd-0 {
  padding: 0 !important;
}


.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 15px !important;
  color: $dark-purple;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -10px !important;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -10px !important;
  left: auto;
}

.sidebar .sidebar-brand-wrapper .sidebar-brand {
  padding: 10px 10px 10px 10px !important;
}

.tab-content {
  border: oldlace;
  border-top: 0;
  padding: 0;
  text-align: justify;
}


// Authentication Modal
.authentication-modal {
  .modal-dialog {
    max-width: 430px;
}
  .modal-header {
    position: absolute;
    right: 0;
    z-index: 10;
    cursor: pointer;
  }
  .auth-tabs {
    margin-bottom: 20px;
    div {
      height: 30px;
      background-color: #000;
      color: #ffffff;
      cursor: pointer;
      border-radius: 40px;
      margin: 5px;

      &.active {
        color: #000000;
        background-color: transparent;
        border-left: 1px solid #000;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
      }
    }
  }

  .auth-form-light {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      text-align: center;
    }

    h6 {
      text-align: center;
    }

    .form-control {
      border-radius: 0;
    }

    .btn-linkedin {
      width: 100%;
    }
  }

  .modal-footer {
    justify-content: center;

    .auth-footer-text {
      text-align: center;
      margin: 0;
    }
  }


}


.bar-loader span {
  background-color: #2a3f54;
}

.jvectormap-container {

  // g:first-of-type() {
  //   transform: scale(0.75);
  // }
}

// Latest event slider
.progress {
  border-radius: 3px;
  height: 20px;
}

.react-bs-table-sizePerPage-dropdown.dropdown {
  .dropdown-menu  {
    min-width: 117px;
  }
}
.swiper-slide{
  margin: 0 ;
  padding: 10px 20px ;
}
.reportCard img{
  border: 1px solid #ddd;
 }
 .reportCard a{
  font-size: 13px;
  font-weight: 600;
  color: #807df8!important;
 }
 .reportCard a:hover{
  font-size: 16px;
  font-weight: 600;
  color: #807df8!important;
  
 }

 img.imagefixed{
  object-fit: cover;
  width: 100%;
  height: auto;
  cursor: pointer;
}
/** Landing page css **/
