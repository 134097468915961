/* Layouts */

// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .page-body-wrapper {
      width: calc(100% - #{$sidebar-width-mini});
      .navbar {
        &.navbar-mini {
          &.fixed-top {
            left: $sidebar-width-mini;
          }
        }
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .nav {
        .sidebar-category {
          text-align: center;
        }
        .nav-item {
          padding: 0;
          .nav-link {
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column);
            text-align: center;
            position: relative;
            border-bottom: none;
            .menu-title {
              display: block;
              margin: auto;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            i {
              &.menu-icon {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 0.625rem;
              }
              &.menu-arrow {
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                top: 50%;
                right: 20px;
                @include transform(translateY(-50%));
              }
            }
          }
        }
      }
    }

    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .navbar {
        left: $sidebar-width-mini;
        .navbar-menu-wrapper {
          width: calc(100% - #{$sidebar-width-mini});
        }
      }
      .sidebar {
        .nav {
          &.sub-menu {
            padding: 0;
            border-top: none;
            .nav-item {
              &::before {
                left: 1.562rem;
              }
              .nav-link {
                padding: 0.75rem 0 0.75rem 0.5rem;
                margin-left: auto;
                margin-right: auto;
                display: block;
                text-align: left;
                width: 66%;
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      left: $sidebar-width-icon;
      .rtl & {
        left: 0;
        right: 150px;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: block;
          margin: 0 auto;
        }
      }
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0;
          .nav-link {
            display: block;
            text-align: center;
            .menu-title,
            .badge,
            .menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            img {
              margin-right: 0;
              margin-left: 0;
            }

            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          &.nav-profile {
            display: none;
          }
          &.nav-category {
            display: none;
          }
          &.nav-doc {
            margin: 0;
            i {
              display: block;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $icon-only-menu-bg-light;
                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-menu-active-bg;
                }
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }
                @at-root #{selector-append(".sidebar-dark", &)} {
                  color: $white;
                }
                &:after {
                  display: none;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: 0.5rem 0;
              background: $icon-only-menu-bg-light;
              @include border-radius(0 0 5px 0);
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-bg;
              }
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                @include border-radius(0 0 0 5px);
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            padding: 0 1.25rem 0 1.25rem;
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-right: auto;
              margin-left: 0;
              .nav-link {
                text-align: right;
                &:before {
                    left: auto;
                    right: 0;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }
    .page-body-wrapper {
      transition: all $action-transition-duration $action-transition-timing-function;
      -webkit-transition: all $action-transition-duration $action-transition-timing-function;
      -moz-transition: all $action-transition-duration $action-transition-timing-function;
      -ms-transition: all $action-transition-duration $action-transition-timing-function;
    }
    .main-panel {
      width: 100%;
      margin-left: 5rem;
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .navbar {
      left: 0;
      .navbar-menu-wrapper {
        width: 100%;
      }
    }
    .sidebar {
      width: 0;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
      }
    }
    .page-body-wrapper {
      width: 100%;
    }
    .main-panel {
      width: 100%;
      margin-left: 5rem;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;
      width: 100%;
      .sidebar {
        transition: none;
        height: 100%;
      }
    }

    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
      }
      .page-body-wrapper {
        width: 100%;
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
      margin-left: 5rem;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media (min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;

      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;

        &.sub-menu {
          max-height: none;
        }
      }
    }

    .page-body-wrapper {
      width: 100%;
      margin-left: $sidebar-width-lg;
    }

    &.sidebar-icon-only {
      .page-body-wrapper {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: $boxed-layout-bg;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }
    .navbar {
      &.fixed-top {
        @media (min-width: 1200px) {
          margin: 0 calc((100% - #{$boxed-container-width}) / 2);
          max-width: $boxed-container-width;
        }
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
      .nav-item {
        .nav-link {
          text-align: middle;
        }
      }
    }
  }
  .page-body-wrapper {
    transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
  }
  .navbar {
    transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    right: $sidebar-width-lg;
    @media (max-width:900px) {
      right: 0;
    }
  }
  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  &.sidebar-icon-only {
    .navbar {
    right: $sidebar-width-icon;
    left: 0;
    }
  }
}

.parallax{
  padding: 30px 0px;
}