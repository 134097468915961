.content-wrapper {
  .page-header {
    h3 {
      span {
        font-size: 15px;
      }
    }
    .btn-header {
      background: $card-bg;
    }
  }
  .survey-head {
    color: $survey-head-color;
    font-size: 14px;
    font-weight: 500;
  }
  .survey-value {
    font-size: 22px;
    margin: 5px 0 0 0;
  }
  .table {
    td {
      img {
        border-radius: 4px;
      }
    }
    .table-user-name {
      small {
        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 6px;
          border-radius: 50px;
          background: $success;
          border: 0;
        }
      }
    }
  }
  .font-12 {
    font-size: 12px;
  }
  .survey-img {
    padding-top: 13px;
    height: 63px;
    width: 80px;
    border-radius: 4px;
  }
  .customer-img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  .card-calender {
    background-image: url("../../assets/images/dashboard/Group4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    h1 {
      font-size: 22px;
    }
    h5 {
      font-weight: 400;
      padding-bottom: 6px;
    }
    h3 {
      font-size: 40px;
      @media screen and (max-width: 991px) {
        font-size: 24px;
      }
    }
    ul {
      li {
        list-style: none;
        padding: 5px 23px 20px 23px;
        margin: 0 3px;
        &.active {
          border-radius: 3px;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #ffffff1f);
        }
      }
    }
  }
  .card-invoice {
    span {
      font-size: 12px;
      color: $text-muted;
    }
    img {
      height: 30px;
      width: 30px;
      border-radius: 4px;
      @media (max-width:560px) {
        margin-top: 6px;
      }
    }
    .reload-outer {
      width: 20px;
      height: 20px;
      padding-top: 5px;
      font-size: 10px;
      border-radius: 50px;
      color: $white;
      text-align: center;
    }
    .list-card {
      padding: 14px;
      border-radius: 3px;
      box-shadow: $card-shadow-color;
      margin-bottom: 12px;
      background: $list-card-bg;
    }
  }
  .color-card {
    .color-card-head {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.25;
    }
  }
  .flot-chart-wrapper {
    .flot-chart {
      width: calc(100% + 54px);
      margin-left: -28px;
      height: 276px;
      margin-bottom: 20px;
            .rtl & {
              margin-left: 0;
              margin-right: -28px;
            }
      .flot-text {
        .flot-x-axis {
          .flot-tick-label {
            color: #a7afb7;
            padding-top: 10px;
            font-size: 12px;
            margin-left: 27px;
            @media (max-width:991px) {
              transform: rotate(45deg);
            }
          }
        }
      }
      @media (max-width: 576px) {
        height: 200px;
      }
    }
  }
  .flot-bar-wrapper {
    .flot-chart {
      height: 51px;
      width: 64px;
    }
  }
}
.dropify-wrapper {
  background: inherit;
}
.list-time {
  margin-left: 28px;
}
.sales-chart-height {
  height: 260px;
}
.outside-month {
  visibility: hidden;
}

.filterdate label {
    margin-right:  10px;
    margin-top: 15px;
    margin-left: 14px;
}

.filterdate .row {
    flex-wrap:  nowrap;
}

.filterdate .react-datepicker-wrapper input.form-control {
    padding:  10px;
    width:  100px !important;
}

.filterdate .row .col-md-6 {
    flex: initial !important;
    max-width: inial !important;
}

.filterdate {
    width:  auto;
    margin-left:  auto;
}
.filter-box {
  z-index: 9999 !important;
}

.filterdate .form-control {
  border: 1px solid #211f72;
  height: 109%;
}

.hide-calendar {
  display: none;
}

.custom-date-row {
    position: relative;
}

.custom-filter-wrap{
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9999;
}

.btn-filter {
  background: #fff !important;
  color: #211f72 !important;
  box-shadow: 0 2px 11px 2px #211f7221;
  border: 1px solid #211f72;
  padding: 10px 12px;
  line-height: 1.2 !important;
}
.custom-filter-wrap .rdrDateRangePickerWrapper{
  box-shadow: 0 8px 20px rgba(0,0,0,0.3);
}
/* .rdrDefinedRangesWrapper .rdrInputRanges {
//   display:none;
// } */
.dashboard-filter .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  width:95px;
}
.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  width:110px;
}
.event-date-filter {
  margin-bottom: 22px !important;
}
.event-date-filter .btn-filter {
  padding:  8px 12px !important;
  box-shadow: none !important;
  border: 1px solid #adb2b5;
}

.event-mb select.form-control {
  padding: 8px;
}

.event-date-filter .custom-filter-wrap{
  z-index: 9999 !important;
}
.search-conainer .pl-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.custom-search-row .sb-1{
  width:100%;
}
.search-conainer .btn-filter {
  padding: 9px 12px;
  border: 1px solid #adb2b5 !important;
  box-shadow: none !important;
}
