.site-footer {
  height: (--footer-height);
  padding: 0.5rem 0;
  // background: #1F2A55;
  background: #090763;
  // background-image: url("../../assets/Group-17.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 3;
.container-ps-footer{
  padding: 1.5rem 4rem 1.5rem 2rem;
}
  .footer-link,
  .copyright-text {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    line-height: 20px;
  }

  .footer-link {
    color: #fff !important;
    display: inline-block;
    margin: 6px 0px;
  }
  .footer-link-main{
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    line-height: 20px;
  }
  .footer-logo{
    //padding-top: 50px;
    width:17rem;
   // height: 50%;
  }
  .Zfooter-logo{
    width:12rem;
    margin-top: 1rem;
  }
  .fa{
    color: #090763;
    font-size: 18px;
  }
  .icondiv{
    background-color: #fff;
    border-style: none;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
  }.icondiv:hover{
    background-color: #6060ed;
    .fa{
      color: #fff;
    }
  }
  .colorFFF{
    color: #fff;
    font-size: 20px;

  }
  .contactparamail{
    .contact-header-mail{
          color: #FFFFFF;
          font-family: "Open Sans", Sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          -webkit-text-stroke-color: #000;
          stroke: #000;
          margin-bottom: 5px;
    }
    a{
      color: #fff;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .link-row{
    display: block;
  }
  .link-row{
    display: block;
  }
  .text-justify-lg{
    text-align: center;
  }
  @media (max-device-width:500px) {
    .Zfooter-logo{
      margin-top: 0rem!important;
    }
    .bgDarkBlue{
      padding: 1rem 2rem!important;
     }
  }
  @media (min-device-width:450px) and (max-device-width:820px) {
    .EmailSubmit {
      padding: 13px 20px;
    }
    .mx-5-mid{
      margin-right: 3rem;
      margin-left: 3rem;
    }
  .text-justify-lg{
    text-align: center;
  }
  }
  @media (max-device-width:449px){
    .mx-5-mid{
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
  .text-justify-lg{
    text-align: center;
  }
  }
  .p15{
    padding-left: 15px;
   } 
   .contact-header{
    margin-bottom: 13px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    -webkit-text-stroke-color: #000;
    stroke: #000;
   }
   .contactpara{
    p{
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
   }
   .bgDarkBlue{
    background-color: #090763;
    padding: 1rem 8rem;
    position: absolute;
   }
   @media (max-device-width: 500px){
.site-footer .bgDarkBlue {
    padding: 1rem 2rem!important;
}
.copyright-text{
  text-align: center;
}
}
   .bottom{
   margin-right: 75px;
   }
}
.nopadding .col-2{
  padding: 0px;
 
}
.nopadding .col-10{
  padding: 0px;
 
}